import React from "react";

import "./Home.css";

export default function Home() {

  function renderContent() {
    return (
      <section className="hero">
        <div contentEditable className="lander">
          <h1 className="hero-title">
            SCISS<span className="expanded-letter">O</span>RSNEST
          </h1>
          <h2 className="hero-subtitle">
            WHERE <span className="bolded-words">PASSION</span> MEETS 
            <br />
            <span className="bolded-words">PROFESSIONALISM</span>
          </h2>
          <h2 className="hero-subtitle">
            Welcome to <span className="bolded-words">the nest</span>.
          </h2>
        </div>
      </section>
    );
  }
  
  return (
    <div className="Home">
      {renderContent()}
    </div>
  );
}
