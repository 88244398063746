import React from "react";
import HeroBanner from "../components/HeroBanner";
import PageContentContainer from "../components/PageContentContainer";

export default function About() {
    function renderContent() {
        return (
            <>
                <HeroBanner pageHeroText="Despre Noi" />
                <PageContentContainer>
                    <h2 className="subtitle">Despre Noi</h2>
                    <p className="textparagraph">
                        Scissorsnest nu este doar un nume, ci o promisiune -
                        promisiunea unui refugiu unde frumusețea întâlnește
                        grija și respectul profund pentru tine. Nu
                        aspirăm să fim cel mai mare salon, ci să fim acel loc de
                        încredere și respectat, unde fiecare vizită este o
                        experiență unică și personalizată. În cuibul nostru,
                        prioritatea este evoluția - nu doar a stilului personal,
                        ci și a modului în care înțelegem și practicăm
                        îngrijirea de sine.
                    </p>
                    <h2 className="subtitle textHighlight">Ambianță</h2>
                    <p className="textparagraph">
                        Scissorsnest este un spațiu care respiră, plin de lumină
                        și viață, proiectat nu doar să inspire liniște și
                        regenerare, ci și să satisfacă nevoile fiecărui client
                        și membru al echipei noastre. Energiile pozitive ale
                        locului sunt amplificate de echilibrul dintre colegi și
                        designul spațiului, creând un mediu în care ne simțim cu
                        toții confortabil și susținut.
                    </p>
                    <p className="textparagraph">
                        Plantele noastre, atent alese, nu doar că îmbogățesc
                        decorul, dar ne încarcă și cu energia lor vibrantă,
                        creând un cadru în care frumusețea naturală și
                        bunăstarea sunt în armonie perfectă. De la o cafea
                        aromată la un ceai senzational, fiecare element este
                        ales cu grijă pentru a vă oferi o experiență de neuitat.
                        La noi, fiecare vizită este ocazia de a lua o pauză de
                        la agitația cotidiană și de a te reîncărca într-un mediu
                        care îmbină armonios frumusețea, confortul și calitatea.
                    </p>
                    <h2 className="subtitle">Filozofia Noastră</h2>
                    <p className="textparagraph">
                        Inima salonului Scissorsnest pulsează prin echipa
                        noastră, un colectiv de profesioniști pasionați care pun
                        suflet în fiecare detaliu al muncii lor. Înțelegem că,
                        deși produsele de înaltă calitate sunt esențiale pentru
                        a crea armonie și a susține standardele noastre de
                        excelență, adevărata esență a frumuseții pe care o
                        oferim clienților noștri provine din măiestria,
                        creativitatea și dedicarea echipei noastre.
                    </p>
                    <p className="textparagraph">
                        La Scissorsnest, credem că frumusețea sustenabilă începe
                        cu oamenii. Fiecare membru al echipei noastre este ales
                        nu doar pentru abilitățile sale tehnice, ci și pentru că
                        împărtășește valorile noastre profunde de respect, grijă
                        și angajament față de fiecare client și față de mediul
                        înconjurător. Această filosofie ne ghidează în toate
                        acțiunile noastre, de la modul în care interacționăm
                        între noi și cu clienții noștri, până la modul în care
                        selectăm produsele cu care lucrăm.
                    </p>
                </PageContentContainer>
            </>
        );
    }

    return <div className="About">{renderContent()}</div>;
}
