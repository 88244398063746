import React from "react";
import "./ProductCard.css";
import ProductDescription from "./ProductDescription";

export default function (props) {
    return (
        <div className="product-card">
            <div className="product-img">
                <img
                    src={props.product.imgLink}
                    alt="image of haircare product"
                />
                <ProductDescription product={props.product} />
                <div className="product-price">
                    <b>{props.product.price}RON</b>
                </div>
            </div>
            <div className="prod-name">
                <h3>
                    <b>{props.product.name}</b>
                </h3>
            </div>
        </div>
    );
}
