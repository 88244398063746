import React from "react";
import PageContentContainer from "../components/PageContentContainer";

export default function Privacy() {
  function renderContent() {
    return (
      <PageContentContainer>
        <h1> Politica de Confidențialitate</h1>
            <h2>1. Introducere</h2>
            <p>
                Vă mulțumim pentru interesul față de compania noastră, produsele
                și/sau serviciile noastre. Când intrați într-o relație de orice
                fel cu noi, ne încredințați informațiile dumneavoastră. Aceste
                informații prezentate în prezentul document (denumit în
                continuare „Politică de confidențialitate”) sunt importante. Vă
                recomandăm să le citiți cu atenție. Scopul acestei Politici de
                confidențialitate este vă explice ce date prelucrăm (colectăm,
                utilizăm, partajăm), de ce le prelucrăm, în ce modalitate le
                prelucrăm, drepturile dvs. în temeiul GDPR și modalitatea în
                care vă puteți exercita aceste drepturi. În colectarea acestor
                informații, noi acționăm în calitate de operator și, prin lege,
                suntem obligați să vă furnizăm aceste informații. Protecția și
                confidențialitatea datelor cu caracter personal este foarte
                importantă pentru noi. Fiind pe deplin conștienți de faptul că
                informațiile personale vă aparțin, facem tot posibilul să le
                stocăm în siguranță și să le prelucrăm cu atenție. Nu oferim
                informații unor părți terțe fără a vă informa în conformitate cu
                prevederile legale. Prin vizitarea site-ului, achiziționarea
                produselor și/sau serviciilor noastre sau prin interacțiunea cu
                noi prin orice mijloc și/sau prin intermediul oricărui canal de
                comunicare (e-mail, telefon, rețele de socializare etc), sunteți
                de acord cu această Politică de confidențialitate și consimțiți
                inclusiv la utilizarea datelor dvs. în scopuri publicitare sau
                de marketing, așa cum am descris mai jos în secțiunile dedicate
                Marketing-ului și Publicității. Dacă nu sunteți de acord cu cele
                descrise în prezenta Politică de Confidențialitate, vă rugăm să
                nu utilizați serviciile sau produselor noastre.
            </p>
            <h2>2. Alte Servicii</h2>
            <p>
                Această Politică de confidențialitate nu acoperă aplicațiile și
                site-urile altor terți la care puteți ajunge prin accesarea
                link-urilor de pe site-ul nostru. Acest lucru excedează
                controlului nostru. Vă încurajăm să examinați Politica de
                confidențialitate de pe orice site și/sau aplicație înainte de a
                furniza date personale.
            </p>
            <h2>3. Cine suntem noi?</h2>
            <p>
                SALON CREATIVE ART SRL (denumita in continuare SCISSORSNEST), este
                responsabilă de prelucrarea datelor dvs. cu caracter personal pe
                care le colectăm direct de la dvs. sau din alte surse. Potrivit
                legislației, compania noastră este operator de date cu caracter
                personal. Pentru ca datele dvs. să fie prelucrate în siguranță,
                am depus toate eforturile să implementăm măsuri rezonabile și
                adecvate, tehnice și organizatorice pentru a proteja
                informațiile dvs. personale.
            </p>
            <h2>4. Cine sunteți dvs?</h2>
            <p>
                Potrivit legislației, dvs., persoana fizică beneficiară a
                produselor și/sau serviciilor noastre, reprezentantul ori
                persoana de contact a unei companii, vizitatorul site-ului sau
                persoana aflată într-o relație de orice fel cu compania noastră,
                sunteți o „persoană vizată”, adică o persoană fizică
                identificată sau identificabilă. Pentru a fi complet
                transparenți în privința prelucrării de date și pentru a vă
                permite să vă exercitați cu ușurință, în orice moment,
                drepturile, am implementat măsuri pentru a facilita informații,
                accesați Secțiunile 7, 10 și 11 din prezentul document.
            </p>
            <h2>5. Angajamentul Nostru</h2>
            <p>
                Protecția informațiilor dvs. personale este foarte importantă
                pentru noi. De aceea, ne- am luat angajamentul să respectăm
                legislația europeană și națională privind protecția datelor cu
                caracter personal, în special Regulamentul (EU) 679/2016,
                cunoscut și sub denumirea de GDPR și următoarele principii:
            </p>
            <ul>
                <li>
                    Legalitate, echitate și transparență Prelucrăm datele dvs.
                    legal și corect. Suntem întotdeauna transparenți în privința
                    informațiilor pe care utilizăm, iar dvs. sunteți informat
                    corespunzător.
                </li>
                <li>
                    Controlul vă aparține În limitele legii, vă oferim
                    posibilitatea de examina, modifica, șterge datele personale
                    pe care le-ați împărtășit cu noi și de a vă exercita
                    celelalte drepturi. Pentru mai multe informații, accesați
                    Secțiunile 7, 10 și 11 din prezentul document.
                </li>
                <li>
                    Integritatea datelor și limitarea scopului Utilizăm datele
                    numai în scopurile descrise la momentul colectării sau în
                    noi scopuri compatibile cu cele inițiale. În toate cazurile,
                    scopurile noastre sunt compatibile cu legislația. Luăm
                    măsuri rezonabile pentru a ne asigura că datele personale
                    sunt corecte, complete și actualizate.
                </li>
                <li>
                    Securitate Am implementat măsuri rezonabile de securitate și
                    criptare, astfel încât să vă protejăm cât mai bine
                    informațiile personale. Cu toate acestea, rețineți faptul că
                    niciun site web, nicio aplicație și nicio conexiune la
                    internet nu este complet sigură.
                </li>
            </ul>
            <h2>6. Schimbări</h2>
            <p>
                Putem schimba această Politică de confidențialitate în orice
                moment. Toate actualizările și modificările prezentei Politici
                sunt valabile imediat după notificare, pe care o vom realiza
                prin afișare pe site și/sau notificare pe e-mail.
            </p>
            <h2>7. Întrebări și solicitări</h2>
            <p>
                Dacă aveți întrebări sau nelămuriri cu privire la prelucrarea
                datelor dvs sau doriți să vă exercitați drepturile legale în
                legătură cu datele pe care le deținem sau dacă aveți îngrijorări
                cu privire la modul în care tratăm orice problemă de
                confidențialitate, ne puteți scrie la adresa de e-mail:
                contact@scissorsnest.ro
            </p>
            <h2>8. Informațiile Dvs.</h2>
            <h3>8.1. Ce fel de informații colectăm despre tine?</h3>
            <p>
                Atunci când navigați pe site-ul nostru, când ne transmiteți o
                solicitare pe e-mail sau ne contactați în orice alt scop și pe
                orice alt canal de comunicare, ne puteți comunica următoarele
                date personale, pe care le colectăm direct de la dvs sau din
                alte surse, cum ar fi:
            </p>
            <ul>
                <li>Numele și prenumele</li>
                <li>Adresa de e-mail</li>
                <li>Numărul de telefon</li>
            </ul>
            Cele mai multe informații le colectăm direct de la dvs. (de exemplu,
            prin completarea unui formular de pe site). Majoritatea informațiile
            sunt cele descrise mai sus, dar pot exista situații în care colectăm
            date de la terți (i.e. parteneri, platforme de publicitate), cum ar
            fi de exemplu, informațiile privind achizițiile și interesele.
            <br />
            <br />
            Pe lângă informațiile indicate mai sus, este posibil să colectăm și
            următoarele informații, în funcție de circumstanțe:
            <ul>
                <li>
                    Modul în care interacționați cu site-ul/site-urile
                    nostru/noastre sau cu reclamele (de exemplu, informații
                    despre cum și când ne accesați site-ul sau ce dispozitiv
                    folosiți pentru a accesa site-ul);
                </li>

                <li>
                    Informații furnizate atunci când completați formulare sau
                    chestionare;
                </li>

                <li>
                    Conținutul mesajelor trimise prin sisteme de mesagerie și
                    e-mail;
                </li>

                <li>
                    Interacțiunile dintre dvs. și noi pe rețelele de socializare
                    (de exemplu, aprecieri, distribuiri, comentarii);
                </li>

                <li>
                    Informații pe care le colectăm despre dvs. de la alte
                    companii din grup sau terțe care au obținut consimțământul
                    dvs. sau au alt drept legal de a partaja aceste informații
                    cu noi (inclusiv parteneri/platforme de publicare sau de
                    publicitate și agregatori de date care au obținut dreptul
                    respectiv).
                </li>
            </ul>
            Este posibil să colectăm date prin module cookie sau alte tehnologii
            similare, precum:
            <ul>
                <li>Adresa IP</li>
                <li>Navigatorul de internet</li>
                <li>Locația</li>
                <li>Paginile web pe care le accesați pe site-ul nostru</li>
            </ul>
            <h3>8.2. De ce colectăm aceste informații?</h3>
            <p>
                Colectăm informațiile cu caracter personal în următoarele
                scopuri:
            </p>
            <ul>
                <li>
                    Pentru a vă răspunde la întrebări și solicitări și a vă
                    furniza serviciul de asistență clienți;
                </li>
                <li>
                    În scop de marketing, însă doar în situația în care avem
                    consimțământul dvs. prealabil sau când există o excepție
                    legală de la obținerea consimțământului;
                </li>
                <li>
                    Pentru a oferi și îmbunătăți serviciile și produsele pe care
                    le oferim;
                </li>
                <li>Pentru a diagnostica sau a remedia probleme tehnice;</li>
                <li>Pentru a ne apăra împotriva atacurilor cibernetice;</li>
                <li>
                    Pentru a ne conforma legislației, ca de exemplu conformarea
                    cu legislația fiscală care ne obligă să păstrăm documentele
                    contabile pe o perioadă de 10 ani;
                </li>
                <li>
                    Pentru constatarea sau revendicarea unui drept în instanță;
                </li>
                <li>În scopuri analitice și de cercetare;</li>
                <li>Pentru desfășurarea promoțiilor și a concursurilor;</li>
                <li>
                    Pentru a preveni infracțiunile, înșelăciunile sau fraudele;
                </li>
            </ul>
            <h3>8.3. Care este temeiul legal pentru prelucrare?</h3>
            <p>
                Putem utiliza următoarele temeiuri legale, în funcție de cazul
                concret:
            </p>
            <ul>
                <li>
                    <b>
                        Consimțământul pentru prelucrarea datelor cu caracter
                        personal;
                    </b>{" "}
                    Cu toate acestea, rețineți că în situația în care sunteți
                    client al nostru, putem trimite mesaje promoționale
                    (marketing direct) cu privire la bunuri și servicii
                    similare, fără a avea nevoie de consimțământ, în temeiul
                    art. 12 alin. (3) din Legea nr. 506/20014. Însă, în toate
                    cazurile, vă puteți opune marketingului direct și/sau vă
                    puteți retrage în orice moment consimțământul prin urmarea
                    instrucțiunilor de dezabonare („unsubscribe”) din fiecare
                    e-mail sau prin transmiterea unei cereri scrise la adresa de
                    e-mail contact@scissorsnest.ro
                </li>
                <li>
                    <b>
                        Prelucrarea este necesară pentru încheierea sau
                        executarea unui contract între dvs și noi;
                    </b>
                </li>
                <li>
                    <b>
                        Prelucrarea este necesară în scopul intereselor noastre
                        legitime sau ale altei părți, cu excepția cazului în
                        care prevalează interesele, drepturile sau libertățile
                        dvs.
                    </b>
                    În situația în care utilizăm interesul legitim, efectuăm o
                    analiză a interesului legitim (test de echilibrare) prin
                    intermediul căreia putem în balanță interesul nostru și
                    interesele dvs. În situația în care prevalează interesele
                    noastre, vom utiliza interesul legitim. În situația în care
                    prevalează interesele dvs., nu vom utiliza interesul
                    legitim, iar în măsura în care nu reușim să identificăm un
                    alt temei legal corect, nu vom efectua respectiva activitate
                    de prelucrare.
                </li>
                <li>
                    <b>
                        Prelucrarea este necesară în vederea îndeplinirii unor
                        obligații legale
                    </b>{" "}
                    (ca de exemplu conformarea cu legislația fiscală care ne
                    obligă să păstrăm documentele contabile pe o perioadă de 10
                    ani sau furnizarea anumitor informații organelor și
                    instituțiilor publice abilitate);
                </li>
                <li>
                    <b>
                        În unele situații, prelucrarea poate fi necesară pentru
                        a proteja interesele vitale ale dvs. sau ale altei
                        persoane fizice;
                    </b>
                    Rețineți faptul că obținerea consimțământului nu este
                    obligatorie, iar noi vom proceda la obținerea
                    consimțământului din partea dvs. doar în situațiile în care
                    nu reușit să utilizăm un alt temei legal.
                </li>
            </ul>
            <h3>8.4. Cât timp stocăm datele?</h3>
            <p>
                Stocăm datele dvs. cu caracter personal doar pe perioada
                necesară îndeplinirii scopurilor, dar nu mai mult de 5 ani de la
                încetarea contractului sau ultima interacțiune cu noi.
                <br />
                După încetarea perioadei, datele cu caracter personal vor fi
                distruse sau șterse din sistemele informatice sau transformate
                în date anonime pentru a fi utilizate în scopuri de cercetare
                științifică, istorică sau statistică.
                <br />
                Reține faptul că în anumite situații expres reglementate, stocăm
                datele pe perioada care ne este impusă de lege.
            </p>
            <h3>8.5. Cum partajăm informațiile dvs. cu ceilalți?</h3>
            <p>
                Putem dezvălui datele dvs., respectând legea aplicabilă,
                partenerilor de afaceri sau altor terți. Depunem în permanență
                eforturi rezonabile pentru a ne asigura că acești terți au
                implementate măsuri adecvate de protecție și securitate. Cu
                acești terți avem clauze contractuale astfel încât datele dvs.
                să fie protejate. În aceste situații, ne vom asigura că orice
                transfer este legitim, având la bază consimțământul dvs. sau alt
                temei juridic.
                <br />
                <br />
                De exemplu, am putea furniza datele dvs. către alte companii,
                precum furnizorii de servicii IT sau telecomunicații,
                contabilitate, servicii juridice și alți terți cu care avem o
                relație contractuală. Acești terți sunt selectați cu o grijă
                deosebită astfel încât datele dvs. să fie prelucrate doar în
                scopurile pe care noi le indicăm.
                <br />
                <br />
                De asemenea, am putea partaja datele dvs. către parteneri de
                afaceri ca urmare a unui efort comun de a oferi un produs sau un
                serviciu.
                <br />
                <br />
                Deși puțin probabil, am putea vinde în viitor afacerea sau o
                parte a afacerii, situație care va include transferul datelor
                dvs.
                <br />
                <br />
                Putem transmite datele și altor părți având consimțământul sau
                potrivit instrucțiunilor dvs, ca de exemplu, în situația în care
                exercitați o cerere de portabilitate.
                <br />
                <br />
                Vom putea, de asemenea, furniza informațiile dvs. cu caracter
                personal și către parchet, poliție, instanțele judecătorești și
                alte organe abilitate ale statului, în baza și în limitele
                prevederilor legale și ca urmare a unor cereri expres formulate.
                <br />
                <br />
                Ne vom asigura, în limite rezonabile, că datele dvs. nu părăsesc
                Spațiul Economic European, însă, în măsura în care vom transfera
                date către state din afara SEE, ne vom asigura, în toate
                cazurile, că transferurile sunt legitime, având la baza
                consimțământul tău explicit sau alt temei legal.
            </p>
            <h2>9. Marketing</h2>
            <p>
                În măsura în care am obținut consimțământul dvs. prealabil sau
                sunteți deja un client al companiei, putem utiliza tehnologii de
                marketing direct și publicitate targhetată, utilizând
                informațiile colectate despre dvs. cu privire la interese,
                preferințe, achiziții, vârstă, locație etc. De exemplu, am putea
                trimite e-mailuri, am putea afișa reclame în interiorul
                site-ului nostru sau pe social media sau am putea plasa reclame
                pe site-uri terțe, în aplicații sau pe alte dispozitive
                conectate la internet.
            </p>
            <h3>9.1. Ce fel de date colectăm pentru marketing?</h3>
            <p>
                În vederea desfășurării activităților de marketing direct sau
                publicitate targhetată, am putea utiliza următoarele informații:
            </p>
            <ul>
                <li>
                    Informații colectate prin intermediul modulelor cookie și a
                    altor tehnologii similare (locație, dispozitiv, navigator,
                    vârstă etc);
                </li>
                <li>
                    Achizițiile dvs, modul în care ați interacționat cu bunurile
                    și serviciile noastre și feedback-ul primit din partea dvs;
                </li>
                <li>Vârsta, țara, regiunea, sexul;</li>
                <li>
                    Alte informații obținute de la partenerii noștri terți de
                    marketing, informații pe care le-au obținut cu acordul dvs.
                </li>
            </ul>
            <h3>9.2. Parteneri de marketing</h3>
            <p>
                Partenerii noștri de marketing, precum Facebook, Google și/sau
                alte agenții ne ajută să transmitem marketing către dvs pe baza
                informațiilor colectate de ei direct de la dvs și cu acordul
                dvs. În unele cazuri, partajăm chiar noi informații pe care
                le-am colectat de la dvs. Ne asigurăm, în toate cazurile, că
                aceste transferuri sunt legale așa cum am explicat la punctul
                8.5.
                <br />
                <br />
                Partenerii noștri pot plasa reclame cu privire la serviciile și
                produsele noastre, în funcție de datele colectate anterior de la
                dvs. (interese, preferințe) pe alte site-uri și/sau servicii. De
                asemenea, partenerii noștri de marketing pot utiliza
                informațiile colectate despre dvs. pentru a îmbunătăți
                serviciile și/sau algoritmii (inclusiv algoritmii bazați pe
                inteligența artificială).
                <br />
                <br />
                Această politică de confidențialitate nu cuprinde informații cu
                privire la modalitatea în care vă prelucrează datele partenerii
                noștri, dar vă încurajăm să citiți politica de confidențialitate
                a partenerilor de marketing pentru mai multe informații. Puteți
                consulta această listă a partenerilor noștri de marketing,
                împreună cu link-urile către Politica de confidențialitate.
            </p>
            <h3>9.3. Cum puteți renunța la marketing direct?</h3>
            <p>
                Vă puteți opune marketingului direct și/sau vă puteți retrage în
                orice moment consimțământul prin urmarea instrucțiunilor de
                dezabonare din fiecare e-mail („unsubscribe” sau „dezabonare”)
                sau prin transmiterea unei cereri în acest sens la adresa de
                e-mail contact@scissorsnest.ro Pentru a dezactiva
                publicitatea bazată pe interese, vă rugăm să consultați în acest
                sens Politica noastră privind utilizarea modulelor cookie .
            </p>
            <h2>10. Care sunt drepturile dvs.?</h2>
            <p>Drepturile dvs. conform Regulamentului GDPR sunt următoarele:</p>
            <ul>
                <li>
                    <b>Dreptul de retragere a consimțământului</b>
                    <br />
                    Vă puteți retrage în orice moment consimțământul cu privire
                    la prelucrarea datelor dvs prin prin transmiterea unei
                    cereri în acest sens la adresa de e-mail
                    contact@scissorsnest.ro. Rețineți însă că, în măsura
                    în care am identificat un alt temei legal pentru prelucrarea
                    datelor dvs, vom continua să prelucrăm datele dvs. în baza
                    acelui temei legal. Noi avem posibilitatea legală să
                    utilizăm unul sau mai multe temeiuri pentru prelucrarea
                    datelor dvs.
                </li>

                <li>
                    <b>
                        Dreptul de a fi informat cu privire la prelucrarea
                        datelor dvs
                    </b>
                </li>

                <li>
                    <b>Dreptul de acces asupra datelor</b>
                    <br />
                    Aveți dreptul de a obține din partea noastră o confirmare că
                    se prelucrează sau nu date cu caracter personal care vă
                    privesc și, în caz afirmativ, acces la datele respective și
                    la informațiile prevăzute de art. 15 alin. (1) din GDPR.
                </li>

                <li>
                    <b>Dreptul de a rectifica datele inexacte sau incomplete</b>
                    <br />
                    Aveți dreptul de a obține, din partea noastră, fără
                    întârzieri nejustificate, rectificarea datelor cu caracter
                    personal inexacte care vă privesc.
                </li>

                <li>
                    <b>Dreptul de ștergere („dreptul de a fi uitat”)</b>
                    <br />
                    În situațiile prevăzute la art. 17 din GDPR, aveți dreptul
                    de a solicita și obține ștergerea datelor cu caracter
                    personal.
                </li>

                <li>
                    <b>Dreptul la restricționarea prelucrării</b> <br />
                    În cazurile prevăzute la art. 18 din GDPR, aveți dreptul de
                    a solicita și obține restricționarea prelucrării.
                </li>

                <li>
                    <b>
                        Dreptul de a transmite datele pe care le avem despre
                        dvs. către alt operator („dreptul la portabilitate”)
                    </b>
                    <br />
                    În cazurile prevăzute la art. 20 din GDPR, aveți dreptul de
                    a solicita și obține portabilitatea datelor.
                </li>

                <li>
                    <b>Dreptul de a vă opune prelucrării datelor</b>
                    <br />
                    În cazurile prevăzute la art. 21 din GDPR, aveți dreptul de
                    a vă opune prelucării datelor.
                </li>

                <li>
                    <b>
                        Dreptul de a nu face obiectul unei decizii bazate
                        exclusiv pe prelucrarea automată, inclusiv crearea de
                        profiluri cu efecte juridice sau cu efecte semnificative
                        similare asupra asupra dvs.
                    </b>
                </li>

                <li>
                    <b>
                        Dreptul de a vă adresa justiției pentru apărarea
                        drepturilor și intereselor dvs.
                    </b>
                </li>

                <li>
                    <b>
                        Dreptul de o plângere în fața unei Autorități de
                        Supraveghere
                    </b>
                </li>
            </ul>
            <br />
            Vă rugăm să rețineți că:
            <ul>
                <li>
                    Vă puteți retrage consimțământul pentru marketing direct în
                    orice moment prin urmarea instrucțiunilor de dezabonare din
                    fiecare e-mail/sms sau alt mesaj electronic.
                </li>
                <li>
                    Dacă doriți să vă exercitați drepturile, o puteți face prin
                    transmiterea unei cereri scrise, semnate și datate la adresa
                    de e-mail: contact@scissorsnest.ro
                </li>
                <li>
                    Drepturile enumerate mai sus nu sunt absolute. Există
                    excepții, de aceea fiecare cerere primite va fi analizată
                    astfel încât să decidem dacă este întemeiată sau nu. În
                    măsura în care cererea este întemeiată, vă vom facilita
                    exercitarea drepturilor. Dacă cererea este neîntemeiată, o
                    vom respinge, însă vă vom informa asupra motivelor refuzului
                    și asupra drepturilor de a depune o plângere la Autoritatea
                    de Supraveghere și de a vă adresa justiției.
                </li>
                <li>
                    Vom încerca să răspundem solicitării în termen de o lună. Cu
                    toate acestea, termenul poate fi prelungit în funcție de
                    diferite aspect, precum complexitatea cererii, numărul mare
                    de cereri primite sau imposibilitatea de a vă identifica
                    într-un termen util.
                </li>
                <li>
                    Dacă, deși depunem toate eforturile, nu reușim să vă
                    identificăm, iar dvs. nu ne furnizați informații
                    suplimentare pentru a reuși să vă identificăm, nu suntem
                    obligați să dăm curs solicitării.
                </li>
            </ul>
            <h2>11. Întrebări, solicitări și exercitarea drepturilor</h2>
            <p>
                Dacă aveți întrebări sau nelămuriri cu privire la prelucrarea
                informațiilor dvs sau doriți să vă exercitați drepturile legale
                sau aveți orice altă îngrijorare cu privire la
                confidențialitate, ne puteți scrie la adresa de e-mail
                {" "}<a href="mailto:contact@scissorsnest.ro">contact@scissorsnest.ro</a> .
            </p>
      </PageContentContainer>
    );
  }

  return <div className="Privacy">{renderContent()}</div>;
}
