import React, { useState, useEffect } from 'react';
import HeroBanner from "../components/HeroBanner";
import PageContentContainer from "../components/PageContentContainer";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProfileCard from "../components/ProfileCard";
import { useAppContext } from "../libs/contextLib";

import "./Team.css";

export default function Team() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { baseURL } = useAppContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(baseURL()+'/teamMembersData.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  function renderContent() {
    return (
      <>
        <HeroBanner pageHeroText="Echipă" />
        {
          isLoading &&
          <div>Loading...</div>
        }

        {
          error &&
          <div>{error}</div>
        }

        {
          isLoading == false &&
          <PageContentContainer>
            <Tabs
              defaultActiveKey="hair"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {Object.keys(data).map((key) => {
                return (
                  <Tab eventKey={key} title={data[key]["displayName"]}>
                    <div className="team-members">
                      {data[key]["people"].map((item) => {
                        return <ProfileCard person={item} />;
                      })}
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </PageContentContainer>

        }
      </>
    );
  }

  return <div className="Team">{renderContent()}</div>;
}
