import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Button from "react-bootstrap/esm/Button";
import Tooltip from "react-bootstrap/Tooltip";

import { IoIosInformationCircleOutline } from "react-icons/io";

import "./ProductDescription.css";

export default function ProductDescription(props) {
    return (
        <OverlayTrigger
            key={props.product.name}
            overlay={
                <Tooltip id={props.product.id}>
                    {props.product.description}
                </Tooltip>
            }
        >
            <Button variant="transparent" className="product-description">
                <IoIosInformationCircleOutline size={24} />
            </Button>
        </OverlayTrigger>
    );
}
