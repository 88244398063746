import React, { useState, useEffect } from "react";
import HeroBanner from "../components/HeroBanner";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import PageContentContainer from "../components/PageContentContainer";
import { useAppContext } from "../libs/contextLib";
import { IoIosInformationCircleOutline } from "react-icons/io";

import "./Services.css";

export default function Services() {
    const { baseURL } = useAppContext();
    const [servicesJson, setServicesJson] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resAdd = baseURL() + "/servicesData.json";
                console.log(resAdd);
                let response = await fetch(resAdd);
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                let jsonData = await response.json();
                setServicesJson(jsonData);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    function renderContent() {
        return (
            <>
                <HeroBanner pageHeroText="Servicii" />
                {isLoading && <div>Loading...</div>}

                {error && <div>{error}</div>}

                {isLoading == false && (
                    <PageContentContainer>
                        <div className="info">
                            <span>
                                <IoIosInformationCircleOutline size={24} />
                            </span>
                            <span>
                                Fiecare membru al echipei evoluează și
                                trece prin diverse niveluri de experiență
                                acumulată. Această experiență, dar și
                                capabilitățile adunate sunt dovada
                                seriozității și dedicării de-a lungul carierei.
                                Acestea sunt, în cele din urmă, reflectate în
                                mod direct în structura noastră de prețuri.
                            </span>
                        </div>
                        <Tabs
                            defaultActiveKey={Object.keys(servicesJson)[0]}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            {Object.keys(servicesJson).map((key) => {
                                return (
                                    <Tab
                                        eventKey={key}
                                        title={servicesJson[key]["displayName"]}
                                    >
                                        <div className="services-table">
                                            <Table className="services-table">
                                                <thead className="services-table-header">
                                                    <tr>
                                                        {servicesJson[key][
                                                            "headers"
                                                        ].map((header) => {
                                                            return (
                                                                <th className="services-table-header">
                                                                    {header}
                                                                </th>
                                                            );
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {servicesJson[key][
                                                        "body"
                                                    ].map((row, index) => {
                                                        return (
                                                            <tr>
                                                                <td className="narrow-table-col">
                                                                    {index + 1}
                                                                </td>
                                                                {row.map(
                                                                    (
                                                                        el,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <td>
                                                                                {
                                                                                    el
                                                                                }
                                                                            </td>
                                                                        );
                                                                    }
                                                                )}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Tab>
                                );
                            })}
                        </Tabs>
                    </PageContentContainer>
                )}
            </>
        );
    }

    return <div className="Services">{renderContent()}</div>;
}
