import React from "react";
import useScreenSize from "../libs/screenSizeLib";
// import { useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import { AiFillHeart, AiOutlineCopyrightCircle } from "react-icons/ai";
import { FaFacebook, FaInstagram, FaMountain } from "react-icons/fa";

import "./Footer.css";

export default function Footer() {
    // let location = useLocation();
    // let history = useHistory();
    let screenSize = useScreenSize();

    useEffect(() => {
        footerProcessing();
    });

    function footerProcessing() {
        if (screenSize.width <= 767) {
            return (
                <footer className="footer-sm-screen">
                    <div className="copyright-text">
                        <span>
                            <AiOutlineCopyrightCircle />
                        </span>{" "}
                        scissorsnest {getCurrentYear()}
                        <br />
                        Created with{" "}
                        <span>
                            <AiFillHeart />
                        </span>{" "}
                        by Digital Nomads in{" "}
                        <span>
                            <FaMountain />
                        </span>{" "}
                        Brașov.
                    </div>
                    <div className="socials-footer">
                        <span>
                            {" "}
                            <a
                                href="https://www.facebook.com/p/scissorsnest-100075967187742/"
                                target="_blank"
                            >
                                {" "}
                                <FaFacebook
                                    size={20}
                                    className="socials-footer-logos"
                                />{" "}
                            </a>{" "}
                        </span>
                        &nbsp; &nbsp; &nbsp;
                        <span>
                            <a
                                href="https://www.instagram.com/scissorsnest/"
                                target="_blank"
                            >
                                <FaInstagram
                                    size={20}
                                    className="socials-footer-logos"
                                />
                            </a>
                        </span>
                    </div>
                    <div className="legals">
                        <Link to="/politica-de-confidentialitate" target="_blank">
                            Politica de Confidențialitate
                        </Link>
                    </div>
                </footer>
            );
        } else if (screenSize.width > 767) {
            return (
                <footer className="footer-lg-screen">
                    <div className="copyright-text">
                        <span>
                            <AiOutlineCopyrightCircle />
                        </span>{" "}
                        scissorsnest {getCurrentYear()} - Created with{" "}
                        <span>
                            <AiFillHeart />
                        </span>{" "}
                        by Digital Nomads in{" "}
                        <span>
                            <FaMountain />
                        </span>{" "}
                        Brașov.
                    </div>
                    <div className="socials-footer">
                        <span>
                            {" "}
                            <a
                                href="https://www.facebook.com/p/scissorsnest-100075967187742/"
                                target="_blank"
                            >
                                {" "}
                                <FaFacebook
                                    size={20}
                                    className="socials-footer-logos"
                                />{" "}
                            </a>{" "}
                        </span>
                        &nbsp; &nbsp; &nbsp;
                        <span>
                            <a
                                href="https://www.instagram.com/scissorsnest/"
                                target="_blank"
                            >
                                <FaInstagram
                                    size={20}
                                    className="socials-footer-logos"
                                />
                            </a>
                        </span>
                    </div>
                    <div className="legals">
                        <Link to="/politica-de-confidentialitate" target="_blank">
                            Politica de Confidențialitate
                        </Link>
                    </div>
                </footer>
            );
        }
    }

    function getCurrentYear() {
        return new Date().getFullYear();
    }

    return footerProcessing();
}
