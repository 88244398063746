import React from "react";
import "./HeroBanner.css";
import "../containers/Home.css";

export default function HeroBanner({
    pageHeroText
}) {

    return (
        <section className="hero-banner-section">
            <img
                src={"/images/"+pageHeroText+".jpeg"}
                alt="scissorsnest about banner"
            />
            <div className="hero-banner-text hero-title">
                {pageHeroText}
            </div>
        </section>
    )
}