import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from 'aws-amplify';
import { BrowserRouter as Router , Switch , Route} from 'react-router-dom';
import './index.css';
import App from './App';
import config from './config';

// import { initSentry } from './libs/errorLib';
import reportWebVitals from './reportWebVitals'

import Footer from "./components/Footer";

import 'bootstrap/dist/css/bootstrap.css';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "scissorsnest",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        service: "execute-api",
        authenticationType: "AWS_IAM"
      },
    ]
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }
});


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    <Switch>
      <Route path={["/despre-noi", "/echipa", "/servicii", "/produse", "/postari", "/contact", "/politica-de-confidentialitate"]}>
          <Footer />
      </Route>
    </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
