import React, { useState, useEffect } from "react";
import Post from "../components/Post";
import "./Posts.css";
import PageContentContainer from "../components/PageContentContainer";
import Accordion from "react-bootstrap/Accordion";
import Offcanvas from "react-bootstrap/Offcanvas";
import Button from "react-bootstrap/Button";
import useScreenSize from "../libs/screenSizeLib";
import { useAppContext } from "../libs/contextLib";

export default function Posts() {
    const { baseURL } = useAppContext();
    const [selectedPost, setSelectedPost] = useState({});
    const [perYearObject, setPerYearObject] = useState({});
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [postsJson, setPostsJson] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleClose = () => setShowOffcanvas(false);
    const handleShow = () => setShowOffcanvas(true);

    let screenSize = useScreenSize();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(baseURL() + '/postsData.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        
        if (jsonData) {
          setPostsJson(jsonData);
          setSelectedPost(jsonData[0]);
          buildPerYearObject(jsonData);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

        fetchData();
    }, []);

    const handlePostSelect = (event) => {
        postsJson.map((article) => {
            if (article.title === event.target.innerText) {
                setSelectedPost(article);
            }
            if (showOffcanvas === true) {
                setShowOffcanvas(false);
            }
        });
    };

    function buildPerYearObject(postsData) {
    let obj = {},
      orderedObj = {};
    postsData.map((article) => {
      let year = article.timestamp.split(".")[2];
      if (obj[year] === undefined) {
        obj[year] = [];
        obj[year].push(article);
      } else {
        obj[year].push(article);
      }
    });
    const sortedKeys = Object.keys(obj).sort((a, b) => b - a);
    sortedKeys.forEach((key) => {
      orderedObj[key] = obj[key];
    });
    setPerYearObject(orderedObj);
  }

    function renderPostsList() {
        return (
            <>
                <h2>Postări recente</h2>
                {postsJson.map((article) => {
                    return <h3 key={article.title} onClick={handlePostSelect}>{article.title}</h3>;
                })}
                <hr className="solid posts-header" />
                <h2 className="posts-header">Postări după an</h2>
                <Accordion
                    defaultActiveKey={new Date().getFullYear().toString()}
                >
                    {Object.keys(perYearObject)
                        .toReversed()
                        .map((year) => {
                            return (
                                <Accordion.Item key={year} eventKey={year}>
                                    <Accordion.Header>{year}</Accordion.Header>
                                    <Accordion.Body>
                                        {perYearObject[year].map((art) => {
                                            return (
                                                <h3 key={art.title} onClick={handlePostSelect}>
                                                    {art.title}
                                                </h3>
                                            );
                                        })}
                                    </Accordion.Body>
                                </Accordion.Item>
                            );
                        })}
                </Accordion>
            </>
        );
    }

    function renderPostsNavigation() {
        if (screenSize.width <= 767) {
            return (
                <>
                    <Button variant="primary" onClick={handleShow}>
                        Alte postări
                    </Button>

                    <Offcanvas show={showOffcanvas} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                                Postări Scissorsnest
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>{renderPostsList()}</Offcanvas.Body>
                    </Offcanvas>
                </>
            );
        } else {
            return <>{renderPostsList()}</>;
        }
    }

    function renderContent() {
        return (
            <>
                {isLoading && <div>Loading...</div>}
                {error && <div>{error}</div>}
                {isLoading == false && !error && (
                    <PageContentContainer>
                        <div className="Posts">
                            <div className="posts-navigation">
                                {renderPostsNavigation()}
                            </div>
                            <div className="post-display">
                                <Post
                                    title={selectedPost.title}
                                    timestamp={selectedPost.timestamp}
                                    imageUrl={selectedPost.imageUrl}
                                    text={selectedPost.text}
                                />
                            </div>
                        </div>
                    </PageContentContainer>
                )}
            </>
        );
    }

    return <>{renderContent()}</>;
}
