export const name_validation = {
    name: "name",
    label: "Nume",
    type: "text",
    id: "name",
    placeholder: "Numele tău",
    nameOfClass: "name",
    validation: {
        required: {
            value: true,
            message: "Câmp obligatoriu",
        },
        maxLength: {
            value: 50,
            message: "50 de charactere maximum",
        },
    },
};
