import React, { Fragment } from "react";
import productsJson from "../data/productsData";
import ProductCard from "./ProductCard";

export default function ProductsDisplay(props) {
    function processDisplay() {
        let buffer = [],
            content = [];
        if (props.searchTerms === "" && props.category === "Toate Categoriile") {
            productsJson.map((item) => {
                buffer.push(
                    <Fragment>
                        <ProductCard product={item} />
                    </Fragment>
                );
            });
        } else if (props.searchTerms !== "" && props.category === "Toate Categoriile") {
            const results = productsJson.filter(
                (item) =>
                    item.name
                        .toLowerCase()
                        .includes(props.searchTerms.toLowerCase()) ||
                    item.tags
                        .toLowerCase()
                        .includes(props.searchTerms.toLowerCase()) ||
                    item.description
                        .toLowerCase()
                        .includes(props.searchTerms.toLowerCase())
            );
            results.map((product) => {
                buffer.push(
                    <Fragment>
                        <ProductCard product={product} />
                    </Fragment>
                );
            });
        } else {
            const results = productsJson.filter(
                (item) => item.category === props.category
            );
            results.map((product) => {
                buffer.push(
                    <Fragment>
                        <ProductCard product={product} />
                    </Fragment>
                );
            });
        }
        buffer.length > 0
            ? content.push(buffer)
            : (content = <p>There are no results</p>);
        return content;
    }
    return (
        <>
            {processDisplay()}
        </>
    );
}
