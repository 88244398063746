export const message_validation = {
    name: "message",
    label: "Mesaj",
    multiline: true,
    id: "message",
    placeholder: "Mesajul tău aici",
    validation: {
        required: {
            value: true,
            message: "Câmp obligatoriu",
        },
        minLength: {
            value: 10,
            message: "Minimum 10 caractere",
        },
        maxLength: {
            value: 200,
            message: "Maximum 200 de caractere",
        },
    },
};
