const prod = {
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://zi0ceqk6ol.execute-api.eu-central-1.amazonaws.com/live",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_RaH8PQTtW",
    APP_CLIENT_ID: "7st08aepd6jfr42rvul4psrc2q",
    IDENTITY_POOL_ID: "eu-central-1:97234909-6e0d-48ca-b418-fd7722cb0774",
  },
  s3: {
    REGION: "eu-central-1",
    BUCKET: "staging-scissorsnest-resources",
  }
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...prod,
};

export default config;
