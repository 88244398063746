import React, { useCallback } from "react";
import { useState, useEffect } from "react";
import HeroBanner from "../components/HeroBanner";
import PageContentContainer from "../components/PageContentContainer";
import { useAppContext } from "../libs/contextLib";
import ProductsDisplay from "../components/ProductsDisplay";
import useScreenSize from ".././libs/screenSizeLib";
import DropdownButton from "react-bootstrap/DropdownButton";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Form from "react-bootstrap/Form";

import { IoInformationCircle } from "react-icons/io5";

import "./Products.css";

export default function Products() {
    const { baseURL } = useAppContext();
    const [productsJson, setProductsJson] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const screenSize = useScreenSize();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("Toate Categoriile");
    const [searchFilter, setSearchFilter] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(baseURL() + "/productsData.json");
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                const jsonData = await response.json();
                let categs = [];
                categs.push("Toate Categoriile");
                jsonData.map((item) => {
                    if (categs.indexOf(item.category) < 0) {
                        categs.push(item.category);
                    }
                });

                categs.sort();
                
                setCategories(categs);
                setProductsJson(jsonData);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    // useEffect(() => {
    //   if (searchFilter !== "") {
    //     setSelectedCategory("All");
    //   }
    // }, [searchFilter]);

    const handleCategoryChange = useCallback((value) => {
        setSelectedCategory(value);
        setSearchFilter("");
        document.getElementById("searchField").value = "";
    });
    const handleSearchChange = useCallback((e) => {
        setSearchFilter(e.target.value);
        setSelectedCategory("Toate Categoriile");
    });

    function renderContent() {
        return (
            <>
                <HeroBanner pageHeroText="Produse" />
                <div className="call-us">
                    <b>
                        <span>
                            <IoInformationCircle
                                size={screenSize.width > 767 ? 24 : 16}
                            />
                        </span>{" "}
                        Dacă sunteți interesat să cumpărați oricare dintre produsele prezentate aici, vă rugăm sunați la{" "}
                        <a href="tel:+40741718128">+40 741 718 128</a>
                    </b>
                </div>
                <PageContentContainer>
                    <div className="products-navigation">
                        <div className="products-categories">
                            <DropdownButton title={selectedCategory}>
                                {categories.map((cat) => {
                                    if (cat !== selectedCategory) {
                                        return (
                                            <DropdownItem
                                                onClick={() =>
                                                    handleCategoryChange(cat)
                                                }
                                            >
                                                {cat}
                                            </DropdownItem>
                                        );
                                    }
                                })}
                            </DropdownButton>
                        </div>
                        <div className="products-search">
                            <Form>
                                <Form.Control
                                    onChange={(e) => handleSearchChange(e)}
                                    placeholder="Căutare"
                                    id="searchField"
                                />
                            </Form>
                        </div>
                    </div>
                    <div className="products">
                        <ProductsDisplay
                            searchTerms={searchFilter}
                            category={selectedCategory}
                        />
                    </div>
                </PageContentContainer>
            </>
        );
    }

    return <div className="Products">{renderContent()}</div>;
}
