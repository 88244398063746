import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import "./Admin.css";
import SalonServicesEditor from '../components/Editors/ServicesEditor';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TeamMemberEditor from "../components/Editors/TeamEditor";
import ProductEditor from '../components/Editors/ProductEditor';
import PostsEditor from "../components/Editors/PostsEditor";
import { API, Auth } from "aws-amplify";

export default function Admin() {
  const { baseURL } = useAppContext();
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [servicesJson, setServicesJson] = useState([]);
  const [teamJSON, setTeamJSON] = useState([]);
  const [productsJSON, setProductsJSON] = useState([]);
  const [postsJson, setsetPostsJson] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {

        Promise.all([
          fetch(baseURL() + '/servicesData.json'),
          fetch(baseURL() + '/teamMembersData.json'),
          fetch(baseURL() + '/postsData.json'),
          fetch(baseURL() + '/productsData.json')
      ])
      .then(async responses => {
          // Get a JSON response for each fetch
          const [res1, res2, res3, res4] = await Promise.all(responses.map(res => res.json()));

          console.log(res1);

          setServicesJson(res1);
          setTeamJSON(res2);
          setsetPostsJson(res3);
          setProductsJSON(res4);
      })
      .catch(error => {
          console.error('There was an error!', error);
          setError(error.message);
      })
      .finally(()=>{
        setIsLoading(false);
      });
    }

    fetchData();
  }, []);

  async function publishAll() {
    console.log(Auth.currentSession());
    return API.get("scissorsnest", `/publish`);
  }

  function renderContent() {
    return (
      <div>
        <h1>Admin</h1>
        <div className="admin-container">
          {
            isLoading &&
            <div>Loading...</div>
          }

          {
            error &&
            <div>{error}</div>
          }

          <button onClick={() => publishAll()}>Publish all</button>
          <Tabs
            defaultActiveKey="Posts"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey={"Posts"} title={"Posts"}>
              <div>
                {
                  !isLoading &&
                  <PostsEditor initialPosts={postsJson} />
                }
              </div>
            </Tab>
            <Tab eventKey={"Services"} title={"Services"}>
              <div>
                {
                  !isLoading &&
                  <SalonServicesEditor initialServices={servicesJson} />
                }
              </div>
            </Tab>
            <Tab eventKey={"Products"} title={"Products"}>
              <div>
                {
                  !isLoading &&
                  <ProductEditor initialProducts={productsJSON} />
                }
              </div>
            </Tab>
            <Tab eventKey={"Team"} title={"Team"}>
              <div>
                {
                  !isLoading &&
                  <TeamMemberEditor initialTeams={teamJSON} />
                }
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }

  return (
    <div className="Admin">
      {renderContent()}
    </div>
  );
}
