import { API } from "aws-amplify";

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]); // Remove the content type prefix
        reader.onerror = error => reject(error);
    });
}

export function getFileExtension(fileName) {

    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(fileName)[0].toLowerCase();

    return ext;
}

  const uploadFile = async (file, key) => {
    const base64Content = await getBase64(file);
    const contentType = file.type;

    const payload = {
      body: {
        fileName: key,
        content: base64Content,
        contentType
      }
    };

    return API.post("scissorsnest", `/upload-file`, payload);
  }

  export default uploadFile;