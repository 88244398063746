import React, { useState } from 'react';
import './ProductEditor.css';
import ProductCard from '../ProductCard';
import { API } from "aws-amplify";
import uploadFile, { getFileExtension } from '../../libs/fileUpload';

const ProductEditor = ({ initialProducts }) => {
  const [products, setProducts] = useState(initialProducts);

  async function saveJSON() {
    return API.put("scissorsnest", `/upload-json/productsData.json`, {
      body: products
    });
  }

  const handleProductChange = (index, field, value) => {
    const updatedProducts = [...products];
    updatedProducts[index] = { ...updatedProducts[index], [field]: value };
    setProducts(updatedProducts);
  };

  const addProduct = () => {
    const newProduct = {
      id: String(products.length + 1),
      name: '',
      description: '',
      category: '',
      price: '',
      tags: '',
      imgLink: ''
    };
    setProducts([...products, newProduct]);
  };

  const deleteProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const uploadImage = async (file, productIndex) => {
    if (products[productIndex].name.length == 0)
    {
      alert("Seteaza intai campul de nume, te rog!");
      return;
    }
  
    const fileKey = `images/product-${productIndex}-${products[productIndex].name}${getFileExtension(file.name)}`;

    try {
      const response = await uploadFile(file, fileKey);
      console.log(JSON.stringify(response));

      handleProductChange(productIndex, 'imageLink', response.Location)
    }
    catch (error) {
      console.error('Error uploading file: ', JSON.stringify(error));
    }
  }

  return (
    <div>
    <button onClick={() => saveJSON()}>Save To Server</button><br/>
      <button onClick={addProduct} className='addButton'>Add Product</button>
    
    <div className='productsContainer'>
    
      {products.map((product, index) => (
        <div>
          <ProductCard product={product}/>
        <div key={product.id} className='productsEntry'>
          <input
            type="text"
            value={product.name}
            onChange={(e) => handleProductChange(index, 'name', e.target.value)}
            placeholder="Name"
          />
          <input
            type="text"
            value={product.category}
            onChange={(e) => handleProductChange(index, 'category', e.target.value)}
            placeholder="Category"
          />
          <input
            type="text"
            value={product.price}
            onChange={(e) => handleProductChange(index, 'price', e.target.value)}
            placeholder="Price"
          />
          <input
            type="text"
            value={product.tags}
            onChange={(e) => handleProductChange(index, 'tags', e.target.value)}
            placeholder="Tags"
          />
          <textarea
            value={product.description}
            onChange={(e) => handleProductChange(index, 'description', e.target.value)}
            placeholder="Description"
          />
          <input
            type="file"
            onChange={(e) => uploadImage(e.target.files[0], index)}
          />
          <button onClick={() => deleteProduct(index)} className='deleteButton'>Delete Product</button>
        </div>
        </div>
      ))}
      
      {false && 
      <pre>{JSON.stringify(products, null, 2)}</pre>}
    </div>
    </div>
  );
};

export default ProductEditor;
/*

  {
      "id": "5",
      "name": "Styling paste",
      "description": "Styling made easier than ever. All day hold.",
      "category": "Paste",
      "price": "80",
      "tags": "normal hair conditioner",
      "imgLink": "https://dtvteknhhdbea.cloudfront.net/images/products/Paste.jpeg"
  }

  */