import React from "react";

import "./Post.css";

export default function (props) {

    const { title, timestamp, imageUrl, text } = props;

    return (
        <div className="post">
            <div className="post-title">
                <h2>{title}</h2>
            </div>
            <div className="post-timestamp">
                {timestamp.toLocaleString()}
            </div>
            <div className="post-image">
                <img src={imageUrl} alt="Image corresponding to post" />
            </div>
            <div className="post-text">
                <pre>
                {text}
                </pre>
            </div>
        </div>
    )
}