import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Admin from "./containers/Admin";
import Contact from "./containers/Contact";
import OurStory from "./containers/OurStory";
import Posts from "./containers/Posts";
import Services from "./containers/Services";
import Products from "./containers/Products";
import Team from "./containers/Team";
import PrivacyPolicy from "./containers/Privacy"

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute>
      <Route exact path="/contact">
        <Contact />
      </Route>
      <Route exact path="/despre-noi">
        <OurStory />
      </Route>
      <Route exact path="/postari">
        <Posts />
      </Route>
      <Route exact path="/servicii">
        <Services />
      </Route>
      <Route exact path="/produse">
        <Products />
      </Route>
      <Route exact path="/echipa">
        <Team />
      </Route>
      <Route exact path="/politica-de-confidentialitate">
        <PrivacyPolicy />
      </Route>
      <AuthenticatedRoute exact path="/admin">
        <Admin />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
