import React, { useState } from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProfileCard from "../ProfileCard";
import './TeamEditor.css';
import { API } from "aws-amplify";
import uploadFile, { getFileExtension } from '../../libs/fileUpload';

const TeamMemberEditor = ({ initialTeams }) => {
  const [teams, setTeams] = useState(initialTeams);

  async function saveJSON() {
    return API.put("scissorsnest", `/upload-json/teamMembersData.json`, {
      body: teams
    });
  }

  const handleTeamMemberChange = (category, index, field, value) => {
    const updatedTeams = { ...teams };
    updatedTeams[category].people[index][field] = value;
    setTeams(updatedTeams);
  };

  const addTeamMember = (category) => {
    const newMember = { firstName: '', lastName: '', imageLink: '', title: '' };
    const updatedTeams = { ...teams };
    updatedTeams[category].people.push(newMember);
    setTeams(updatedTeams);
  };

  const deleteTeamMember = (category, index) => {
    const updatedTeams = { ...teams };
    updatedTeams[category].people.splice(index, 1);
    setTeams(updatedTeams);
  };

  const handleDisplayNameChange = (category, value) => {
    const updatedTeams = { ...teams };
    updatedTeams[category].displayName = value;
    setTeams(updatedTeams);
  };

  const addNewTeam = () => {
    const newTeamKey = `team_${Object.keys(teams).length + 1}`;
    const newTeam = {
      displayName: 'New Team',
      people: []
    };
    setTeams({ ...teams, [newTeamKey]: newTeam });
  };

  const handleDeleteTeam = (category, index) => {
    const updatedTeams = { ...teams };
    delete updatedTeams[category];
    setTeams(updatedTeams);
  };

  const uploadImage = async (file, category, memberIndex, member) => {
    if (member.firstName.length == 0 && member.lastName.length == 0)
    {
      alert("Seteaza intai campurile de nume, te rog!");
      return;
    }
  
    const fileKey = `images/team-${member.firstName}-${member.lastName}${getFileExtension(file.name)}`;

    try {
      const response = await uploadFile(file, fileKey);
      console.log(JSON.stringify(response));
      handleTeamMemberChange(category, memberIndex, 'imageLink', response.Location);
    }
    catch (error) {
      console.error('Error uploading file: ', JSON.stringify(error));
    }
  }

  return (
    <div>
      <button onClick={() => saveJSON()}>Save To Server</button><br/>
      <button onClick={addNewTeam} className={"addButton"}>Add New Team</button>
      <Tabs
        defaultActiveKey="hair"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {Object.entries(teams).map(([category, { displayName, people }]) => (

          <Tab eventKey={category} title={displayName}>
            <div>
            <button onClick={() => addTeamMember(category)} className={"addButton"}>Add Team Member</button>
              <button onClick={() => handleDeleteTeam(category)} className={"deleteButton"}>Delete Team</button>
            </div>
            <div key={category} className={"teamContainer"}>
              <label>Team Name</label>
              <input
                type="text"
                value={displayName}
                onChange={(e) => handleDisplayNameChange(category, e.target.value)}
                placeholder="Team Name"
              />
              <label>Members</label>
              {people.map((member, index) => (
                <div key={index} className={"teamMember"}>
                  <ProfileCard person={member} />
                  <input
                    type="text"
                    value={member.firstName}
                    onChange={(e) => handleTeamMemberChange(category, index, 'firstName', e.target.value)}
                    placeholder="First Name"
                  />
                  <input
                    type="text"
                    value={member.lastName}
                    onChange={(e) => handleTeamMemberChange(category, index, 'lastName', e.target.value)}
                    placeholder="Last Name"
                  />
                  <input
                    type="text"
                    value={member.title}
                    onChange={(e) => handleTeamMemberChange(category, index, 'title', e.target.value)}
                    placeholder="Title"
                  />
                  <input
                    type="file"
                    disabled={member.firstName.length == 0 || member.lastName.length == 0}
                    onChange={(e) => uploadImage(e.target.files[0], category, index, member)}
                  />
                  <button onClick={() => deleteTeamMember(category, index)}>Delete Member</button>
                </div>
              ))}

            </div>
          </Tab>
        ))}
      </Tabs>
      
      {false && <pre>{JSON.stringify(teams, null, 2)}</pre>}
    </div>
  );
};

export default TeamMemberEditor;
