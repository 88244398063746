export default async function submitForm(formData) {
    const response = await fetch('https://zi0ceqk6ol.execute-api.eu-central-1.amazonaws.com/live/send-email', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: formData.name,
            subject: "Formular contact scissorsnest.ro " + (new Date()).toString(),
            email: formData.email,
            message: formData.message,
        }),
    });
    
    if (!response.ok) {
        throw new Error('Failed to send email');
    }

    return response.json();
}
