import React, { useState } from 'react';
import Post from '../Post';
import './PostsEditor.css';
import { API } from "aws-amplify";

const PostsEditor = ({ initialPosts }) => {
  const [posts, setPosts] = useState(initialPosts);

  async function saveJSON() {
    return API.put("scissorsnest", `/upload-json/postsData.json`, {
      body: posts
    });
  }

  const handlePostChange = (index, field, value) => {
    const updatedPosts = [...posts];
    updatedPosts[index] = { ...updatedPosts[index], [field]: value };
    setPosts(updatedPosts);
  };

  const addPost = () => {
    const newPost = {
      id: String(posts.length + 1),
      name: '',
      description: '',
      category: '',
      price: '',
      tags: '',
      imgLink: '',
      timestamp: 'now'
    };
    setPosts([...posts, newPost]);
  };

  const deletePost = (index) => {
    const updatedPosts = posts.filter((_, i) => i !== index);
    setPosts(updatedPosts);
  };

  const uploadImageToS3 = async (file, postIndex) => {
    // Configure AWS S3 instance here
    // const s3 = new S3();
    // const params = { Bucket: 'your-bucket-name', Key: 'file-key', Body: file };
    // try {
    //   const response = await s3.upload(params).promise();
    //   handleTeamMemberChange(category, memberIndex, 'imageLink', response.Location);
    // } catch (error) {
    //   console.error('Error uploading file: ', error);
    // }
  };

  return (
    <>
    <button onClick={() => saveJSON()}>Save To Server</button>
    
    <div className='postsContainer'>
    <button onClick={addPost}>New Post</button>
      {posts.map((post, index) => (
        <div>
        <div key={post.id} className='postsEntry'>
          <input
            type="text"
            value={post.title}
            onChange={(e) => handlePostChange(index, 'title', e.target.value)}
            placeholder="Title"
          />
          <input
            type="text"
            value={post.timestamp}
            onChange={(e) => handlePostChange(index, 'timestamp', e.target.value)}
            placeholder="Timestamp"
          />
          <input
            type="text"
            value={post.tags}
            onChange={(e) => handlePostChange(index, 'tags', e.target.value)}
            placeholder="Tags"
          />
          <textarea
            value={post.text}
            onChange={(e) => handlePostChange(index, 'text', e.target.value)}
            placeholder="Text"
          />
          <input
            type="file"
            onChange={(e) => uploadImageToS3(e.target.files[0], index)}
          />
          <button onClick={() => deletePost(index)}>Delete Post</button>
        </div>
        <Post
              title={post.title}
              timestamp={post.timestamp}
              text={post.text}
            />
        </div>
      ))}
      
      {false && 
      <pre>{JSON.stringify(posts, null, 2)}</pre>}
    </div>
    </>
  );
};

export default PostsEditor;
/*

  {
      "id": "5",
      "name": "Styling paste",
      "description": "Styling made easier than ever. All day hold.",
      "category": "Paste",
      "price": "80",
      "tags": "normal hair conditioner",
      "imgLink": "https://dtvteknhhdbea.cloudfront.net/images/posts/Paste.jpeg"
  }

  */