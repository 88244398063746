import React from "react";

import styles from "./PageContentContainer.module.css";

export default function PageContentContainer (props) {
    return(
        <section className={styles.container}>
            {props.children}
        </section>
    )
}