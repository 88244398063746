import React, { useState } from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "react-bootstrap/Table";
import "./ServicesEditor.css";
import { API } from "aws-amplify";

const SalonServicesEditor = ({ initialServices }) => {
  const [services, setServices] = useState(initialServices);

  async function saveJSON() {
    return API.put("scissorsnest", `/upload-json/servicesData.json`, {
      body: services
    });
  }


  const handleServiceChange = (category, rowIndex, colIndex, value) => {
    const updatedServices = { ...services };
    updatedServices[category].body[rowIndex][colIndex] = value;
    setServices(updatedServices);
  };

  const handleHeaderChange = (category, index, value) => {
    const updatedServices = { ...services };
    if (value === 'delete' && index > 1) {
        // Remove the header
        updatedServices[category].headers.splice(index, 1);
  
        // Remove the corresponding data from each service row
        updatedServices[category].body.forEach(row => {
          row.splice(index-1, 1);
        });
      }
      else {
          updatedServices[category].headers[index] = value;
      }
    setServices(updatedServices);
  };

  const handleDisplayNameChange = (category, value) => {
    const updatedServices = { ...services };
    updatedServices[category].displayName = value;
    setServices(updatedServices);
  };

  const addService = (category) => {
    const updatedServices = { ...services };
    const newRow = new Array(updatedServices[category].headers.length-1).fill('');
    updatedServices[category].body.push(newRow);
    setServices(updatedServices);
  };

  const addCategory = () => {
    const newCategoryKey = `category_${Object.keys(services).length + 1}`;
    const updatedServices = {
      ...services,
      [newCategoryKey]: {
        headers: ["", "Service", "Cost"],
        body: [],
        displayName: "New Category"
      }
    };
    setServices(updatedServices);
  };

  const addHeader = (category) => {
    const updatedServices = { ...services };
    updatedServices[category].headers.push('New Header');
    updatedServices[category].body.forEach(row => row.push(''));
    setServices(updatedServices);
  };

  const deleteService = (category, rowIndex) => {
    const updatedServices = { ...services };
    updatedServices[category].body.splice(rowIndex, 1);
    setServices(updatedServices);
  };

  const deleteCategory = (category) => {
    const updatedServices = { ...services };
    delete updatedServices[category];
    setServices(updatedServices);
  };

  return (
    <div className='services-container'>
      <button onClick={() => saveJSON()}>Save To Server</button>
      <div className='services-editor-form'>
      <button onClick={addCategory}>Add Department</button>
              <Tabs
              defaultActiveKey={Object.keys(services)[0]}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
      {Object.entries(services).map(([category, categoryData]) => (
        <Tab eventKey={category} title={services[category]["displayName"]}>
        <div key={category} >
          <input
            type="text"
            value={categoryData.displayName}
            onChange={(e) => handleDisplayNameChange(category, e.target.value)}
            placeholder="Display Name"
          />
          <button onClick={() => deleteCategory(category)}>Delete Department</button>
          <div>
            {categoryData.headers.map((header, index) => (
              <input
                key={index}
                type="text"
                value={header}
                onChange={(e) => handleHeaderChange(category, index, e.target.value)}
                placeholder="Header"
              />
            ))}
            <button onClick={() => addHeader(category)}>Add Column</button>
          </div>
          {categoryData.body.map((row, rowIndex) => (
            <div key={rowIndex}>
                <input
                  key={"colIndexX"}
                  type={"text"}
                  value={'#'}
                />
              {row.map((cell, colIndex) => (
                <input
                  key={colIndex}
                  type={"text"}
                  value={cell}
                  onChange={(e) => handleServiceChange(category, rowIndex, colIndex, e.target.value)}
                  placeholder={categoryData.headers[colIndex+1]}
                />
              ))}
              <button onClick={() => deleteService(category, rowIndex)}>Delete Service</button>
            </div>
          ))}
          <button onClick={() => addService(category)}>Add Service</button>
        </div>
        </Tab>
      ))}
      </Tabs>
      </div>
      <Tabs
              defaultActiveKey={Object.keys(services)[0]}
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              {Object.keys(services).map((key) => {
                return (
                  <Tab eventKey={key} title={services[key]["displayName"]}>
                    <div className="services-table">
                      <Table className="services-table">
                        <thead className="services-table-header">
                          <tr>
                            {services[key]["headers"].map((header) => {
                              return <th className="services-table-header">{header}</th>
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {services[key]["body"].map((row, index) => {
                            return (
                              <tr>
                                <td className="narrow-table-col">{index + 1}</td>
                                {row.map((el, index) => {
                                  return <td>{el}</td>
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </Tab>
                )
              })}
            </Tabs>
            
      {false&&<pre>{JSON.stringify(services, null, 2)}</pre>}

    </div>
  );
};

export default SalonServicesEditor;
