import React from "react";
import './ProfileCard.css';

export default function (props) {
    return (
        <div className="person-card">
            <div className="person-img">
                <img
                    src={props.person.imageLink}
                    alt="image of team member"
                />
            </div>
            <div className="name-title">
                <h3>
                    {props.person.firstName} <b>{props.person.lastName}</b>
                </h3>
                <h4>
                    {props.person.title}
                </h4>
            </div>
        </div>
    );
}