"use client";

import { useState } from "react";

import { Input } from "./Input.js";
import { FormProvider, useForm } from "react-hook-form";
import { name_validation } from "../utils/nameValidation";
import { email_validation } from "../utils/emailValidation";
import { message_validation } from "../utils/messageValidation";
import { GrMail } from "react-icons/gr";
import { FaCheck, FaExclamationTriangle } from "react-icons/fa";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import sendEmail from "../utils/sendEmail.js";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

import styles from "../containers/Contact.css";

export default function ContactFormNew() {
    const methods = useForm();

    const [policyCheck, setPolicyCheck] = useState(false);
    const [emailSentCheck, setEmailSentCheck] = useState(null);
    const [show, setShow] = useState(true);

    const onSubmit = methods.handleSubmit((data) => {
        handleSubmit(data);
        methods.reset();
    });

    const handleCheckboxChange = () => {
        console.log("checkbox clicked");
        if (policyCheck === true) {
            setPolicyCheck(false);
        } else {
            setPolicyCheck(true);
        }
    };

    const handleSubmit = async (data) => {
        try {
            await sendEmail(data);
            setEmailSentCheck(true);
            setPolicyCheck(false);
            setTimeout(() => {
                setEmailSentCheck(null);
            }, 3000);
        } catch (ex) {
            console.log(ex.message);
            setEmailSentCheck(false);
            setPolicyCheck(false);
            setTimeout(() => {
                setEmailSentCheck(null);
            }, 3000);
        }
    };

    return (
        <>
            <div className={styles.contactForm}>
                <h2 className={`${styles.formTitle} headlineTitle`}>
                    Formular de contact
                </h2>
                <FormProvider {...methods}>
                    <form
                        onSubmit={(e) => e.preventDefault()}
                        noValidate
                        className="contactFormFields"
                        id="contactFormFields"
                        autoComplete="off"
                    >
                        <Input {...name_validation} />
                        <Input {...email_validation} />
                        <Input {...message_validation} />
                        <Form.Check
                            type="checkbox"
                            id="checkbox"
                            className="checkboxArea"
                            checked={policyCheck}
                            label={
                                <span className="checkboxText">
                                    Dând click pe această căsuță, sunteți de
                                    acord cu{" "}
                                    <Link
                                        to="/privacy-policy"
                                        target="_blank"
                                        className="privacyLink"
                                    >
                                        Politica de Confidențialitate
                                    </Link>
                                </span>
                            }
                            onChange={handleCheckboxChange}
                        />
                        <div className={styles.errorMsgArea}>
                            <button
                                className="submitButton"
                                type="submit"
                                disabled={policyCheck === false}
                                onClick={onSubmit}
                            >
                                <span>
                                    <GrMail />
                                </span>{" "}
                                Trimite
                            </button>
                        </div>
                    </form>
                </FormProvider>
            </div>
            {(emailSentCheck === true || emailSentCheck === false) && (
                <ToastContainer
                    position="top-end"
                    className="p-3"
                    styles={{ zIndex: 1 }}
                >
                    <Toast
                        onClose={() => setShow(false)}
                        show={show}
                        autohide
                        delay={5000}
                    >
                        <Toast.Header>
                            {emailSentCheck ? (
                                <>
                                    <FaCheck size={20} />{" "}
                                    <strong className="me-auto">
                                        Mesajul a fost trimis cu succes!
                                    </strong>
                                </>
                            ) : (
                                <>
                                    <FaExclamationTriangle size={20} />{" "}
                                    <strong className="me-auto">
                                        Trimiterea mesajului a eșuat. Te rugăm
                                        să încerci mai târziu.
                                    </strong>
                                </>
                            )}
                        </Toast.Header>
                        {emailSentCheck ? (
                            <Toast.Body>
                                Mulțumim frumos! Vă vom răspunde solicitării în
                                cel mai scurt timp posibil.
                            </Toast.Body>
                        ) : (
                            <Toast.Body>
                                În mod alternativ, vă rugăm frumos să încercați
                                să ne contactați via una din celalalte metode.
                            </Toast.Body>
                        )}
                    </Toast>
                </ToastContainer>
            )}
        </>
    );
}
