import React, { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useHistory, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import ErrorBoundary from "./components/ErrorBoundary";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import Routes from "./Routes";
import "./App.css";
import useScreenSize from "./libs/screenSizeLib";
import { Auth } from "aws-amplify";

export default function App() {
  const history = useHistory();
  let location = useLocation();
  let screenSize = useScreenSize();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  
  function rotatedTextProcessing() {
    if(location.pathname === '/') {
      return (
        <div className="rotated-text-white">
          hair&style creatives<br />driven by ethics
        </div>
      )
    } else if (location.pathname !== '/' && screenSize.width <= 767) {
      return (
        <div className="rotated-text">
          hair&style creatives driven by ethics
        </div>
      )
    } else {
      return (
        <div className="rotated-text">
          hair&style creatives<br />driven by ethics
        </div>
      )
    }
  }

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/login");
  }

  function baseURL() {
    return isAuthenticated ? 
    "https://dtvteknhhdbea.cloudfront.net":
    "https://dtvteknhhdbea.cloudfront.net";
  }

  return (
    !isAuthenticating && (
      <div className="App-container">
        <Navbar collapseOnSelect bg="white" expand="md" className="align-items-center sticky-top px-3">
          <LinkContainer to="/">
            <Navbar.Brand className="navbarelements">
              <img
                src={"/images/logo.png"}
                height="16px"
                alt="scissorsnest logo"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-center auto-width navbar-container">
            <Nav activeKey={window.location.pathname} className="flex-grow auto-width navbar-items">
              <LinkContainer to="/despre-noi">
                <Nav.Link className="navbarelements">Despre Noi</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/echipa">
                <Nav.Link className="navbarelements">Echipă</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/servicii">
                <Nav.Link className="navbarelements">Servicii</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/produse">
                <Nav.Link className="navbarelements">Produse</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/postari">
                <Nav.Link className="navbarelements">Postări</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact">
                <Nav.Link className="navbarelements">Contact</Nav.Link>
              </LinkContainer>
              {
                isAuthenticated && <LinkContainer to="/admin">
                <Nav.Link className="navbarelements">Admin</Nav.Link>
              </LinkContainer>
              }
              {
                isAuthenticated && <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <ErrorBoundary>
          <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, baseURL }}>
            <Routes />
          </AppContext.Provider>
        </ErrorBoundary>
        
        {rotatedTextProcessing()}
      </div>
    )
  );
}
