export const email_validation = {
    name: "email",
    label: "Email",
    type: "email",
    id: "email",
    placeholder: "adresa.ta.de@email.com",
    className: "email",
    validation: {
        required: {
            value: true,
            message: "Câmp obligatoriu",
        },
        pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Email invalid",
        },
    },
};
