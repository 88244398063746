import React, { useState } from "react";
import HeroBanner from "../components/HeroBanner";
import PageContentContainer from "../components/PageContentContainer";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FaMapMarkerAlt, FaFacebook, FaInstagram } from "react-icons/fa";

import Form from "../components/Form";

import "./Contact.css";

export default function Contact() {

  function renderContent() {
    return (
      <>
        <HeroBanner pageHeroText="Contact" />
        <PageContentContainer>
          <div className="contact-container">
            <div className="contact-subcontainer">
              <div className="address">
                
                <h2>Adresă</h2>
                <p> <FaMapMarkerAlt size={20} /> <a href="https://maps.app.goo.gl/4HFLKNa5AsRo4uuN8">Strada Sitei nr. 52, Brasov, Romania</a></p>
              </div>
              <div className="contact-details">
                <h2>Contact</h2>
                <p><AiOutlinePhone size={20}/> <a href="tel:0040741718128">+40 741 718 128</a></p>
                <p><AiOutlineMail size={20}/> <a href="mailto:contact@scissorsnest.ro">contact@scissorsnest.ro</a></p>
              </div>
              <div className="socials">
                <h2>Social Media</h2>
                <p><FaFacebook size={24} /> <a href="https://www.facebook.com/p/scissorsnest-100075967187742/" target="_blank">scissorsnest</a></p>
                <p><FaInstagram size={24} /> <a href="https://www.instagram.com/scissorsnest/" target="_blank">@scissorsnest</a></p>
              </div>
            </div>
            <div className="form-subcontainer">
              <Form />
            </div>
          </div>
        </PageContentContainer>
      </>
    )
  }

  return <div className="Contact">{renderContent()}</div>;
}
